import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';

import { bgBlur } from '../../../utils/cssStyles';
import Iconify from '../../iconify';
import AccountPopover from './components/AccountPopover';

const DRAWER_WIDTH = 280;
const NAVBAR_MOBILE = 64;
const NAVBAR_DESKTOP = 70;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.custom.green }),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: NAVBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: NAVBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));


const Navbar = ({ onOpenNav }) => {
    return (
        <StyledRoot>
            <StyledToolbar>
                <IconButton
                    onClick={onOpenNav}
                    sx={{
                        mr: 1,
                        color: '#fff',
                        display: { lg: 'none' },
                    }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>

                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.25,
                        sm: 0.5,
                    }}
                >
                    <AccountPopover />
                </Stack>
            </StyledToolbar>
        </StyledRoot>
    );
};

export default Navbar;
