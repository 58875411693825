import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import useResponsive from "../hooks/useResponsive";

export default function CustomModal(props) {
    const { openModal, setOpenModal } = props;
    const isDesktop = useResponsive('up', 'lg');

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isDesktop ? "40%" : "90%",
        bgcolor: "background.paper",
        border: "0px solid #000",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        boxSizing: "border-box",
        height: "auto",
        maxHeight: "90vh",
        overflow: "auto",
    };

    return (
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                {props.children}
            </Box>
        </Modal>
    );
}