import React, { useState, useEffect } from "react";
import CustomTable from "../../table/CustomTable";
import CustomButton from "../../buttons/CustomButton";
import { Box, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../modal/CustomModal";
import CustomInput from "../../inputs/CustomInput";
import MessageAlert from "../../alerts/MessageAlert";
import { handleAlertClose } from '../../alerts/alertUtils';

export default function ConferencesPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [alertOpen, setAlertOpen] = useState(false);

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [nameEdit, setNameEdit] = useState("");
    const [startDateEdit, setStartDateEdit] = useState("");
    const [endDateEdit, setEndDateEdit] = useState("");
    const [id, setId] = useState("");

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/conferences/get-all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const handleAdd = () => {
        if (!name || !startDate || !endDate) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                name, startDate, endDate
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/conferences/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setData(result?.data);
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenAddModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const handleEdit = () => {
        if (!nameEdit || !startDateEdit || !endDateEdit) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                id, 
                name: nameEdit, 
                startDate: startDateEdit, 
                endDate: endDateEdit
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/conferences/edit`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setData(result?.data);
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenEditModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const tableAction = (row) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                <button
                    style={{ backgroundColor: "#29166F", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                    onClick={() => {
                        navigate(`/conferences/${row?._id}`);
                    }}>
                    View
                </button>
                <button
                    style={{ backgroundColor: "#29166F", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                    onClick={() => {
                        setId(row?._id);
                        setNameEdit(row?.name);
                        setStartDateEdit(row?.startDate);
                        setEndDateEdit(row?.endDate);
                        setOpenEditModal(true);
                    }}>
                    Edit
                </button>
                {/* <button
                    style={{ backgroundColor: "#29166F", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                    onClick={() => {
                        //navigate(`/conferences/${row?._id}/participants`);
                        navigate(`/conferences/${row?._id}/participants/${row?._id}`);
                    }}>
                    Participants
                </button> */}
            </div>
        );
    };

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openAddModal} setOpenModal={setOpenAddModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Add Conference
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenAddModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#29166F" }} />
                    </div>
                </div>
                <CustomInput
                    label="Conference Name"
                    placeholder="Enter the conference name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <CustomInput
                    label="Start Date"
                    placeholder="Enter the start date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    type="date"
                />
                <CustomInput
                    label="End Date"
                    placeholder="Enter the end date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    type="date"
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAdd()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openEditModal} setOpenModal={setOpenEditModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Edit Conference
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenEditModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#29166F" }} />
                    </div>
                </div>
                <CustomInput
                    label="Conference Name"
                    placeholder="Enter the conference name"
                    value={nameEdit}
                    onChange={(e) => setNameEdit(e.target.value)}
                />
                <CustomInput
                    label="Start Date"
                    placeholder="Enter the start date"
                    value={startDateEdit}
                    onChange={(e) => setStartDateEdit(e.target.value)}
                    type="date"
                />
                <CustomInput
                    label="End Date"
                    placeholder="Enter the end date"
                    value={endDateEdit}
                    onChange={(e) => setEndDateEdit(e.target.value)}
                    type="date"
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleEdit()}>
                    Save
                </CustomButton>
            </CustomModal>
            <div className="page-header-row">
                <h2 style={{}}>Conferences</h2>
                <div className="buttons-wrapper">
                    <div>
                        <CustomButton
                            fullWidth
                            size="medium"
                            type="submit"
                            variant="contained"
                            onClick={() => setOpenAddModal(true)}
                        >
                            Add Conference
                        </CustomButton>
                    </div>
                </div>
            </div>
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                    <CircularProgress color="#29166F" />
                </Box>
                :
                <CustomTable
                    tableHeader={
                        [
                            { id: 'name', label: 'Name', numeric: false },
                            { id: 'startDate', label: 'Start Date', numeric: false, date: true },
                            { id: 'endDate', label: 'End Date', numeric: false, date: true },
                            //{ id: 'subEventNumber', label: 'Number of Events', numeric: false },
                        ]
                    }
                    tableData={data}
                    tableAction={tableAction}
                />
            }
        </>
    );
}