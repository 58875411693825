import React from 'react';
import { styled } from '@mui/material';
import { Outlet } from "react-router-dom";

import LogoImage from '../../assets/images/logo.png';

const AuthLayout = () => {

    return (
        <LayoutWrapper>
            <FormWrapper>
                <img src={LogoImage} width="70%" alt="login" style={{alignSelf: "center"}} />
                <Outlet />
            </FormWrapper>
        </LayoutWrapper>
    );
};

export default AuthLayout;

const LayoutWrapper = styled('div')(() => ({
    backgroundColor: '#F9FAFB', // F5F8F9
    width: '100%',
    height: '100vh',
    padding: '0rem 0rem',
    display: 'flex',
    flexDirection: 'row',
    border: '0px solid red',
    boxSizing: 'border-box',
}));

const FormWrapper = styled('div')(() => ({
    maxWidth: 480, // 480
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: "20px",
    boxSizing: "border-box",
    border: '0px solid green',
    "& .form": {
        border: "0px solid red",
        padding: 20,
        margin: 15,
        boxSizing: 'border-box',
        backgroundColor: "#fff",
    },
    "& .linkRow": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
        "& .link": {
            color: "#00701F"
        }
    }
}));