import React, { useState, useCallback } from 'react';
import { QrReader } from 'react-qr-reader';

function QrScanner() {
  const [url, setUrl] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleScan = useCallback(async (data) => {
    if (data && !url) {
      setUrl(data);
      try {
        const res = await fetch(data);
        if (!res.ok) throw new Error('Request failed');
        const result = await res.json(); // Adjust based on expected response type
        setResponse(result);
      } catch (err) {
        setError(err.message);
      }
    }
  }, [url]);

  const handleError = (err) => {
    setError(err.message);
  };

  const handleNewScan = () => {
    // Reset states to allow for a new scan
    setUrl(null);
    setResponse(null);
    setError(null);
  };

  return (
    <div>
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            handleScan(result?.text);
          }
          if (!!error) {
            handleError(error);
          }
        }}
        style={{ width: '100%' }}
      />
      {url && <p>Scanned URL: {url}</p>}
      {response && <p>Response: {JSON.stringify(response)}</p>}
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}

      {/* Button to scan a new QR code */}
      {url && (
        <button onClick={handleNewScan}>
          Scan Another URL
        </button>
      )}
    </div>
  );
}

export default QrScanner;
