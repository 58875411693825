import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import CustomTable from "../../table/CustomTable";
import CustomButton from "../../buttons/CustomButton";
import { Box, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../modal/CustomModal";
import CustomInput from "../../inputs/CustomInput";
import MessageAlert from "../../alerts/MessageAlert";
import { handleAlertClose } from '../../alerts/alertUtils';
import Card from "../../card/Card";

export default function ConferencePage() {
    const { conferenceId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openParticipantModal, setOpenParticipantModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [alertOpen, setAlertOpen] = useState(false);

    const [idNo, setIdNo] = useState("");
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [venue, setVenue] = useState("");
    const [idNoEdit, setIdNoEdit] = useState("");
    const [nameEdit, setNameEdit] = useState("");
    const [dateEdit, setDateEdit] = useState("");
    const [timeEdit, setTimeEdit] = useState("");
    const [venueEdit, setVenueEdit] = useState("");
    const [id, setId] = useState("");

    const [participantsFile, setParticipantsFile] = useState("");

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/conferences/get/${conferenceId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [conferenceId]);

    const handleAdd = () => {
        if (!name || !date || !time || !venue) {
            setAlertMessage("All fields are required except ID No!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                idNo, name, date, time, venue, conferenceId
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/conferences-events/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setData(result?.data);
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenAddModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const handleEdit = () => {
        if (!nameEdit || !dateEdit || !timeEdit || !venueEdit) {
            setAlertMessage("All fields are required except ID No!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                id: id,
                idNo: idNoEdit,
                name: nameEdit,
                date: dateEdit,
                time: timeEdit,
                venue: venueEdit,
                conferenceId
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/conferences-events/edit`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setData(result?.data);
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                        setOpenEditModal(false);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const handleAddParticipant = () => {
        if (!participantsFile) {
            setAlertMessage("Participants file is required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

            const formdata = new FormData();
            formdata.append("file", participantsFile);
            formdata.append("conferenceId", conferenceId);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/participants/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occurred, try again!");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    };

    const tableAction = (row) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                <button
                    style={{ backgroundColor: "#29166F", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                    onClick={() => {
                        setId(row?._id);
                        setIdNoEdit(row?.idNo);
                        setNameEdit(row?.name);
                        setDateEdit(row?.date);
                        setTimeEdit(row?.time);
                        setVenueEdit(row?.venue);
                        setOpenEditModal(true);
                    }}>
                    Edit
                </button>
                <button
                    style={{ backgroundColor: "#29166F", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                    onClick={() => {
                        navigate(`/conferences/${conferenceId}/participants/${row?._id}`);
                    }}>
                    Participants
                </button>
            </div>
        );
    };

    const contentRef = useRef(null);
    const handlePrint = useReactToPrint({ contentRef });

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openAddModal} setOpenModal={setOpenAddModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Add Conference Event
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenAddModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#29166F" }} />
                    </div>
                </div>
                <CustomInput
                    label="Event ID"
                    placeholder="Enter the event ID e.g ARN/2024/ABJ/01"
                    value={idNo}
                    onChange={(e) => setIdNo(e.target.value)}
                />
                <CustomInput
                    label="Name"
                    placeholder="Enter the event name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <CustomInput
                    label="Date"
                    placeholder="Enter the date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    type="date"
                />
                <CustomInput
                    label="Time"
                    placeholder="Enter the time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    type="time"
                />
                <CustomInput
                    label="Venue"
                    placeholder="Enter the venue"
                    value={venue}
                    onChange={(e) => setVenue(e.target.value)}
                    type="text"
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAdd()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openEditModal} setOpenModal={setOpenEditModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Edit Conference Event
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenEditModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#29166F" }} />
                    </div>
                </div>
                <CustomInput
                    label="Event ID"
                    placeholder="Enter the event ID e.g ARN/2024/ABJ/01"
                    value={idNoEdit}
                    onChange={(e) => setIdNoEdit(e.target.value)}
                />
                <CustomInput
                    label="Name"
                    placeholder="Enter the event name"
                    value={nameEdit}
                    onChange={(e) => setNameEdit(e.target.value)}
                />
                <CustomInput
                    label="Date"
                    placeholder="Enter the date"
                    value={dateEdit}
                    onChange={(e) => setDateEdit(e.target.value)}
                    type="date"
                />
                <CustomInput
                    label="Time"
                    placeholder="Enter the time"
                    value={timeEdit}
                    onChange={(e) => setTimeEdit(e.target.value)}
                    type="time"
                />
                <CustomInput
                    label="Venue"
                    placeholder="Enter the venue"
                    value={venueEdit}
                    onChange={(e) => setVenueEdit(e.target.value)}
                    type="text"
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleEdit()}>
                    Save
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openParticipantModal} setOpenModal={setOpenParticipantModal} >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Add Participants
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenParticipantModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#29166F" }} />
                    </div>
                </div>
                <div>
                    <h3 style={{ marginTop: 0, paddingTop: 0 }}>Use the sample <a href="/sample-participants.csv" download="sample-participants.csv">here, download sample</a></h3>
                </div>
                <form encType="multipart/form-data">
                    <CustomInput
                        label="Participants Excel"
                        placeholder="Enter the event ID e.g ARN/2024/ABJ/01"
                        //value={participantsFile}
                        //onChange={(e) => setParticipantsFile(e.target.value)}
                        onChange={(e) => setParticipantsFile(e.target.files[0])}
                        type="file"
                    />

                    <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAddParticipant()}>
                        Submit
                    </CustomButton>
                </form>
            </CustomModal>
            <div className="page-header-row">
                <h2 style={{}}>{data?.conference?.name}</h2>
                <div className="buttons-wrapper">
                    <div>
                        <CustomButton
                            fullWidth
                            size="medium"
                            type="submit"
                            variant="contained"
                            onClick={() => setOpenAddModal(true)}
                        >
                            Add Event
                        </CustomButton>
                    </div>
                    <div>
                        <CustomButton
                            fullWidth
                            size="medium"
                            type="submit"
                            variant="contained"
                            onClick={() => setOpenParticipantModal(true)}
                        >
                            Add Participants
                        </CustomButton>
                    </div>
                    <div>
                        <CustomButton
                            fullWidth
                            size="medium"
                            type="submit"
                            variant="contained"
                            onClick={() => handlePrint()}
                        // loading={buttonLoading}
                        // disabled={buttonLoading}
                        >
                            Print Tags
                        </CustomButton>
                    </div>
                </div>
            </div>
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                    <CircularProgress color="#29166F" />
                </Box>
                :
                <>
                    <CustomTable
                        tableHeader={
                            [
                                { id: 'idNo', label: 'ID', numeric: false },
                                { id: 'name', label: 'Name', numeric: false },
                                { id: 'date', label: 'Date', numeric: false, date: true },
                                { id: 'time', label: 'Time', numeric: false },
                                { id: 'venue', label: 'Venue', numeric: false },
                            ]
                        }
                        tableData={data?.events ? data?.events : []}
                        tableAction={tableAction}
                    />
                    <div className="print-only">
                        <div ref={contentRef} className="print-container">
                            {data?.participants?.map((card, index) => (
                                <div key={index} className="card-wrapper">
                                    <Card data={card} />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
        </>
    );
}