import { Paper } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../buttons/CustomButton";
import CustomInput from "../../inputs/CustomInput";
import { useNavigate } from "react-router-dom";
import MessageAlert from "../../alerts/MessageAlert";
import { handleAlertClose } from '../../alerts/alertUtils';

export default function LoginPage() {
    const navigate = useNavigate();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonLoading, setButtonLoading] = useState('');

    const handleClick = () => {
        if(!email || !password){
            setAlertMessage("Email and password are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                email: email,
                password: password
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            
            fetch(`${window.backendUrl}/api/v1/auth/login`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        localStorage.setItem("userToken", result?.data?.token);
                        localStorage.setItem("userFullname", result?.data?.userData?.name);
                        navigate("/");
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    return(
        <Paper elevation={2} className="form">
            <MessageAlert 
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <h3 style={{textAlign: 'center', marginTop: 0,}}>Login</h3>
            <CustomInput
                size="medium"
                variant="outlined"
                name="email"
                type="email"
                label="Email Address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
            <CustomInput
                size="medium"
                variant="outlined"
                name="password"
                type="password"
                label="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
            />
            <CustomButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                onClick={() => handleClick()}
                loading={buttonLoading}
                disabled={buttonLoading}>
                Submit
            </CustomButton>
        </Paper>
    );
}