import { useState } from 'react';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { AccountCircle } from "@mui/icons-material";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
//import { useSelector } from 'react-redux';

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  // const { userData } = useSelector((state) => state.userData);
  // const account = userData?.user;
  // console.log(userData);
  const handleOpen = (event) => {
    event.preventDefault();
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  }

  const handleLogout = () => {
    setOpen(null);
    Swal.fire({
      //title: 'Are you sure you want to logout?',
      title: 'Logout',
      text: "Are you sure you want to logout?",
      //icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        localStorage.removeItem("userToken");
        navigate("/auth/login");
      }
    });
  };

  const userFullname = localStorage.getItem("userFullname");

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          gap: 1,
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '0',
              position: 'absolute',
              bgcolor: 'transparent',
            },
          }),
        }}
      >
        <AccountCircle style={{ width: 40, height: 40, color: '#fff' }} />
        <span style={{ fontSize: 16, color: '#fff' }}>{userFullname}</span>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          p: 0,
          mt: 2,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
            width: 160,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userFullname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            Admin
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
