import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import styled from '@emotion/styled';

import LogoImageIcon from '../../../../assets/images/icon.png';
//import { useSelector } from 'react-redux';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  //const { userData } = useSelector((state) => state.userData);
  //const schoolName = userData?.school?.name;

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: '100%',
        height: 50,
        display: 'flex',
        boxSizing: 'border-box',
        ...sx,
      }}
      {...other}
    >
      <LogoWrapper className="logo">
        <LogoIcon src={LogoImageIcon} />
        <Typography variant="h6" noWrap component="div" color='#fff'>
          ARN Conference
        </Typography>
      </LogoWrapper>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents', boxSizing: 'border-box', }}>
      {logo}
    </Link>
  );
});

export default Logo;

const LogoWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
  zIndex: 9999,
  gap: '5px',
}));
const LogoIcon = styled('img')(() => ({
  height: '50px',
  marginRight: '10px',
  borderRadius: '5px',
}));