import { Button, CircularProgress } from '@mui/material';

export default function CustomButton(props) {
    const { disabled, loading, variant, startIcon, endIcon, size, onClick } = props;
    
    return(
        <Button
            disabled={disabled || loading}
            variant="contained" 
            endIcon={loading ? null : endIcon} 
            startIcon={loading ? null : startIcon}
            fullWidth 
            onClick={onClick}
            sx={
                variant === "outlined" ?
                {
                    textTransform: "None",
                    backgroundColor: 'transparent', 
                    border: '1px solid #29166F',
                    color: '#29166F',
                    height: (size === "small" && '35px') || (size === "medium" && '45px') || (size === "large" && '55px') || (size === undefined && '45px'),
                    boxShadow: 0,
                    '&:hover': {
                        backgroundColor: 'transparent !important;'
                    },
                    '&:disabled': {
                        backgroundColor: 'transparent !important;',
                    }
                }
                :
                {
                    textTransform: "None",
                    backgroundColor: '#29166F', 
                    color: '#FFFFFF',
                    height: (size === "small" && '35px') || (size === "medium" && '45px') || (size === "large" && '55px') || (size === undefined && '45px'),
                    boxShadow: 0,
                    '&:hover': {
                        backgroundColor: '#066AAB !important;'
                    },
                    '&:disabled': {
                        backgroundColor: '#066AAB !important;',
                    }
                }
            }
            >
            {loading ? <CircularProgress size="20px" sx={variant === "outlined" ? {color: '#29166F'} : {color: '#FFFFFF'}} /> :<>{props.children}</> }
        </Button>
    )
}

