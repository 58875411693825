import React from "react";
import MenuItem from "./MenuItem";

export default function NavMenu({ data, onCloseNav }) {
    return (
        <div style={{padding: '10px', boxSizing: 'border-box'}}>
            {data.map((item, index) => (
                <MenuItem item={item} key={index} onCloseNav={onCloseNav} />
            ))}
        </div>
    )
}