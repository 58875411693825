import { styled } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

import bgImage from "../../assets/images/card-bg.png";

export default function Card({ data }){
    console.log(data);
    return(
        <Wrapper>
            <div className="header"></div>
            <div className="content">
                <div className="textWrapper">
                    <div className="text textName">{data?.title} {data?.name}</div>
                    <div className="text textCategory">{data?.category}</div>
                    <div className="text textAppliation">{data?.appliation}</div>
                    <div className="date">19th - 23rd Nov, 2024</div>
                </div>
                <div className="qrWrapper">
                    <QRCode 
                        style={{ height: "auto", maxWidth: "120px", width: "120px" }}
                        value={`https://arn-api.onrender.com/api/v1/participants/checkin/${data?.conferenceId}/${data?._id}`} 
                    />
                    <div className="idNoText">{data?.idNo}</div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled('div')(() => ({
    width: '4.135in',
    height: '5.845in',
    boxSizing: "border-box",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    gap: '0px',
    border: '0px solid red',
    "& .header": {
        height: '2.63in',
    },
    "& .content": {
        height: '3.21in',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'center',
        gap: '0px',
        border: '0px solid red',
        boxSizing: 'border-box',
    },
    "& .textWrapper": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        gap: '5px',
        border: '0px solid red',
        boxSizing: 'border-box',
        "& .date": {
            width: "60%",
            fontSize: "14px",
            backgroundColor: "#29166F",
            color: "#fff",
            textAlign: 'center',
            padding: '4px',
            boxSizing: 'border-box',
            fontWeight: 600,
            alignSelf: 'center',
        }
    },
    "& .qrWrapper": {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        border: '0px solid red',
        boxSizing: 'border-box',
        gap: '5px',
        "& .idNoText": {
            fontWeight: 700,
        }
    },
    "& .text": {
        fontWeight: 700,
        width: '100%',
        fontWeight: 700,
        textAlign: 'center',
    },
    "& .textName": {
        fontSize: '30px',
    },
    "& .textCategory": {
        fontSize: '22px',
        fontWeight: 500,
    },
    "& .textAppliation": {
        fontSize: '18px',
        fontWeight: 500,
    },
}));