import React, { useState } from "react";
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

export default function MenuItem({ item, onCloseNav }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        if (item?.children) {
            setOpen(!open);
        } else {
            navigate(item?.path);
            onCloseNav();
        }
    };

    const handleSubClick = (path) => {
        console.log(path);
        navigate(path);
        onCloseNav();
    }

    const isActive = (path) => location.pathname === path;
    const isInclude = (path) => {
        return location.pathname.includes(path) && path !== "/"
    };

    return (
        <>
            <ListItemButton
                onClick={handleClick}
                aria-expanded={open}
                aria-controls="students-menu"
                className={(isActive(item?.path) || isInclude(item?.path)) ? "active" : ""}
                sx={{
                    height: 48,
                    mt: '2px',
                    mb: '2px',
                    position: 'relative',
                    textTransform: 'capitalize',
                    color: '#637381',
                    borderRadius: 0,
                    boxSizing: 'border-box',
                    '&.active': {
                        color: '#fff',
                        bgcolor: 'custom.green',
                        fontWeight: 'fontWeightBold',
                        // borderTopRightRadius: '48px',
                        // borderBottomRightRadius: '48px',
                        // borderTopLeftRadius: '5px',
                        // borderBottomLeftRadius: '5px',
                        borderRadius: '5px',
                        '&:hover': {
                            bgcolor: 'custom.green',
                        }
                    },
                    '&:hover': {
                        bgcolor: 'custom.green',
                        color: '#fff',
                        // borderTopRightRadius: '48px',
                        // borderBottomRightRadius: '48px',
                        // borderTopLeftRadius: '5px',
                        // borderBottomLeftRadius: '5px',
                        borderRadius: '5px',
                    }
                }}
            >
                <ListItemIcon style={{ color: 'inherit' }}>
                    {item?.icon}
                </ListItemIcon>
                <ListItemText primary={item?.title} primaryTypographyProps={{fontSize: "14px"}} />
                {item?.children && <>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </>}
            </ListItemButton>
            {item?.children &&
                <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    id="students-menu">
                    <List component="div" disablePadding>
                        {item?.children?.map((child, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => handleSubClick(child?.path)}
                                className={isActive(child?.path) ? "active" : ""}
                                sx={{
                                    pl: 4,
                                    mt: '2px',
                                    mb: '2px',
                                    height: 48,
                                    position: 'relative',
                                    textTransform: 'capitalize',
                                    color: '#637381',
                                    borderRadius: 0,
                                    width: "100%",
                                    boxSizing: 'border-box',
                                    '&.active': {
                                        color: '#fff',
                                        bgcolor: '#637381',
                                        fontWeight: 'fontWeightBold',
                                        borderRadius: '5px',
                                        '&:hover': {
                                            bgcolor: 'custom.green',
                                        }
                                    },
                                    '&:hover': {
                                        bgcolor: 'custom.green',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }
                                }}
                                >
                                <ListItemIcon style={{ color: 'inherit' }}>
                                    {child?.icon}
                                </ListItemIcon>
                                <ListItemText primary={child?.title} primaryTypographyProps={{fontSize: "14px"}} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            }
        </>
    )
}