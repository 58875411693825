import React from 'react';
import { Box, Drawer, Stack } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import useResponsive from '../../hooks/useResponsive';
import Scrollbar from '../../scrollbar';
import Logo from './components/Logo';
import CustomButton from '../../buttons/CustomButton';
import NavMenu from './components/NavMenu';
import { navAdminConfig } from './config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const DRAWER_WIDTH = 280;

const Sidebar = ({ openNav, onCloseNav }) => {
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');

  const handleLogout = () => {
    Swal.fire({
      //title: 'Are you sure you want to logout?',
      title: 'Logout',
      text: "Are you sure you want to logout?",
      //icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        localStorage.removeItem("userToken");
        navigate("/auth/login");
      }
    });
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: `calc(100% - 140px)`,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        marginTop: '70px',
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{
        px: 1,
        py: '10px',
        display: 'inline-flex',
        position: 'fixed',
        top: 0,
        width: DRAWER_WIDTH,
        height: 70,
        zIndex: 9999,
      }}>
        <Logo />
      </Box>
  
      {/* <NavSection data={navAdminConfig} /> */}
      <NavMenu data={navAdminConfig} onCloseNav={onCloseNav} />
  
      <Box sx={{ flexGrow: 1 }} />
  
      <Box sx={{ px: 1.5, pb: 2, mt: 5, position: 'fixed', bottom: 0, width: DRAWER_WIDTH, boxSizing: 'border-box' }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <CustomButton
            //loading={buttonLoading}
            variant="contained"
            endIcon={<LogoutIcon />}
            onClick={() => handleLogout()}
          >
            Logout
          </CustomButton>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: DRAWER_WIDTH },
        boxSizing: 'border-box',
      }}
    >
      {isDesktop ?
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#1C1C25',
              color: '#FFFFFF',
              borderRightStyle: 'dashed',
              boxSizing: 'border-box',
            },
          }}
        >
          {renderContent}
        </Drawer>
        :
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#1C1C25',
              color: '#FFFFFF',
              boxSizing: 'border-box',
            },
          }}
        >
          {renderContent}
        </Drawer>
      }
    </Box>
  );
};

export default Sidebar;
