import HomeIcon from '@mui/icons-material/Home';
import CampaignIcon from '@mui/icons-material/Campaign';

const navAdminConfig = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    title: 'Conferences',
    path: '/conferences',
    icon: <CampaignIcon />,
  },
];

export { navAdminConfig };

