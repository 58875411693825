import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import AuthLayout from './components/layout/AuthLayout';
import DashboardLayout from './components/layout/DashboardLayout';

import LoginPage from './components/pages/auth/LoginPage';
import DashboardPage from './components/pages/dashboard/DashboardPage';
import ParticipantsPage from './components/pages/conferences/ParticipantsPage';
import ConferencesPage from './components/pages/conferences/ConferencesPage';
import ConferencePage from './components/pages/conferences/ConferencePage';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route element={<DashboardLayout />}>
          <Route path="/" element={<DashboardPage />} index />
          <Route path="/participants" element={<ParticipantsPage />} />
          <Route path="/conferences" element={<ConferencesPage />} />
          <Route path="/conferences/:conferenceId" element={<ConferencePage />} />
          <Route path="/conferences/:conferenceId/participants/:eventId" element={<ParticipantsPage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
