// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Customize your primary color
    },
    secondary: {
      main: '#dc004e', // Customize your secondary color
    },
    custom: {
        green: '#29166F'
    }
  },
  typography: {
    // Customize typography if needed
  },
  // Add other customizations
});

export default theme;
