import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Outlet, useNavigate } from "react-router-dom";

import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';

const DashboardLayout = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userToken = localStorage.getItem("userToken");
        if(!userToken){
            navigate("/auth/login");
        }
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Navbar onOpenNav={() => setOpen(true)} />
            <Sidebar openNav={open} onCloseNav={() => setOpen(false)} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: "100%", boxSizing: 'border-box'}}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default DashboardLayout;
