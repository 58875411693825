import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/system";
import { Box, CircularProgress } from "@mui/material";
import CustomTable from "../../table/CustomTable";
import CustomModal from "../../modal/CustomModal";
import CustomButton from "../../buttons/CustomButton";
import QRScanner from "../../QR/QRScanner";
import QrScanner from "../../QR/QRReader";
import { useParams } from "react-router-dom";
import MessageAlert from "../../alerts/MessageAlert";
import { handleAlertClose } from '../../alerts/alertUtils';
import Card from "../../card/Card";

export default function ParticipantsPage() {
    const { eventId } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filteredParticipantsData, setFilteredParticipantsData] = useState([]);
    const [selectedTabCategory, setSelectedTabCategory] = useState("all");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [alertOpen, setAlertOpen] = useState(false);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/conferences-events/get/${eventId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [eventId]);

    useEffect(() => {
        let filtered = data?.participants;
        if(selectedTabCategory === 'checkedIn'){
            filtered = data?.participants?.filter(item => item.checkedIn === true);
        } else if(selectedTabCategory === 'notCheckedIn'){
            filtered = data?.participants?.filter(item => item.checkedIn === false);
        } else {
            filtered = data?.participants;
        }
        setFilteredParticipantsData(filtered);
        console.log(filtered)
    }, [data, selectedTabCategory]);

    const tableAction = (row) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                {row?.checkedIn ?
                    <button
                        disabled
                        style={{ opacity: 0.5, backgroundColor: "#29166F", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                        onClick={() => {
                            //console.log(row);
                        }}>
                        Checked
                    </button>
                    :
                    <button
                        disabled={buttonLoading}
                        style={{ backgroundColor: "#29166F", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                        onClick={() => {
                            handleCheckIn(row);
                        }}>
                        {buttonLoading ? "Loading..." : "Check In"}
                    </button>
                }
            </div>
        );
    };

    const handleCheckIn = async (participant) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/participants/checkin/${data?.event?._id}/${participant?._id}/${participant?.conferenceId}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            console.log(result);
            if (result?.code === 200) {
                setData(result?.data);
                setAlertMessage(result?.message);
                setAlertType("success");
                setAlertOpen(true);
            } else {
                setAlertMessage(result?.message);
                setAlertType("error");
                setAlertOpen(true);
            }
            setButtonLoading(false);
        })
        .catch((error) => {
            console.error(error);
            setAlertMessage("An error occurred, try again!");
            setAlertType("error");
            setAlertOpen(true);
            setButtonLoading(false);
        });
    }

    const [openModal, setOpenModal] = useState(false);

    const scanClick = () => {
        setOpenModal(true);
    }

    const rightButton = <CustomButton
        fullWidth
        size="medium"
        onClick={() => alert('okay')}
    >
        Scan Another
    </CustomButton>

    const [scanLoading, setScanLoading] = useState(false);

    const onScan = async (url) => {
        const eventId = 1;
        if (url && !scanLoading) {
            setScanLoading(true);
            console.log('AGM => ', url);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
            fetch(`${url}/${eventId}`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    //console.log(result);
                    if (result?.code === 200) {

                    }
                    //setScanLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    //setScanLoading(false);
                });
        }
    }


    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openModal} onClose={() => setOpenModal(false)} title="Check In Participants" rightButton={rightButton}>
                {/* <QRScanner onScan={onScan} /> */}

                <QrScanner />
            </CustomModal>
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                    <CircularProgress color="#29166F" />
                </Box>
                :
                <>
                    <div className="page-header-row">
                        <h2 style={{}}>{data?.conference?.name} / Participants</h2>
                        <div className="buttons-wrapper">
                            <div></div>
                        </div>
                    </div>
                    <h3 style={{ margin: 0, padding: 0 }}>{data?.event?.name}</h3>
                    <div className="tabs-wrapper">
                        <TabsContainer>
                            <TabsList>
                                <Tab
                                    isSelected={selectedTabCategory === "all"}
                                    onClick={() => setSelectedTabCategory("all")}
                                >
                                    All
                                </Tab>
                                <Tab
                                    isSelected={selectedTabCategory === "checkedIn"}
                                    onClick={() => setSelectedTabCategory("checkedIn")}
                                >
                                    CheckedIn
                                </Tab>
                                <Tab
                                    isSelected={selectedTabCategory === "notCheckedIn"}
                                    onClick={() => setSelectedTabCategory("notCheckedIn")}
                                >
                                    Not CheckedIn
                                </Tab>
                            </TabsList>
                        </TabsContainer>
                    </div>

                    <CustomTable
                        tableHeader={
                            [
                                { id: 'idNo', label: 'ID', numeric: false },
                                { id: 'category', label: 'Category', numeric: false },
                                { id: 'title', label: 'Title', numeric: false },
                                { id: 'name', label: 'Name', numeric: false },
                                { id: 'email', label: 'Email', numeric: false },
                                { id: 'phone', label: 'Phone', numeric: false },
                                { id: 'appliation', label: 'Appliation', numeric: false },
                            ]
                        }
                        tableData={filteredParticipantsData ? filteredParticipantsData : []}
                        tableAction={tableAction}
                        isParticipants={true}
                        scanClick={scanClick}
                    />
                </>
            }
        </>
    );
}

const TabsContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    marginBottom: "0.75rem",
    marginTop: "1rem",
    width: '100%',
}));

const TabsList = styled("div")(() => ({
    display: "flex",
    flexDirection: 'row',
    gap: "0rem",
    borderBottom: "0.1rem solid #ccc",
    listStyle: "none",
    width: '100%',
}));

const Tab = styled("div")(({ isSelected }) => ({
    padding: "0.5rem",
    margin: "0.2rem",
    borderRadius: "0.25rem",
    borderBottom: `${isSelected ? "0.25rem solid #29166F" : ""}`,
    color: `${isSelected ? "black" : "#6b7280"}`,
    cursor: "pointer",
    width: '33%',
    fontSize: '12px',
    textAlign: 'center',
}));